<template>
	<v-container fill-height scroll-y fluid>
		<v-layout align-start justify-center>
			<v-flex xs10 sm8 md6 xl4>
				<v-form ref="form">
					<v-card class="noBackground">
						<v-card-title>
							<v-layout align-center justify-center my-3 row>
								<v-flex xs12 sm10 md8 xl6>
									<v-img v-if="logo" :src="logo" contain :max-height="$vuetify.breakpoint.mdAndUp ? '25vh' : '15vh'"></v-img>
								</v-flex>
							</v-layout>
						</v-card-title>
						<v-card-title>
							<v-layout align-center justify-center :display-1="$vuetify.breakpoint.mdAndUp" :title="$vuetify.breakpoint.smAndDown" text-xs-center>
								<span>{{ $t('password_create.title', { accountingfirm: name }) }}</span>
							</v-layout>
							<v-layout align-center justify-center :display-1="$vuetify.breakpoint.mdAndUp" :title="$vuetify.breakpoint.smAndDown" text-xs-center>
								<p class="headline text-xs-center" style="margin-top: 10px">{{ $t('password_create.sub_title') }}</p>
							</v-layout>
						</v-card-title>
						<v-card-text>
							<v-tooltip bottom>
								<template v-slot:activator="{ on }">
									<v-text-field
										v-model="password"
										:type="revealPassword1 ? 'text' : 'password'"
										:label="$t('signin.fields.password')"
										:rules="[rules.required, rules.webdavCompliant]"
										autofocus
										:append-icon="revealPassword1 ? 'visibility_off' : 'visibility'"
										v-on="on"
										@click:append="revealPassword1 = !revealPassword1"
									></v-text-field>
								</template>
								<span>{{ $t('signin.fields.rules') }}</span>
							</v-tooltip>

							<v-tooltip bottom>
								<template v-slot:activator="{ on }">
									<v-text-field
										v-model="password_confirmation"
										:type="revealPassword2 ? 'text' : 'password'"
										:label="$t('signin.fields.password_confirmation')"
										:append-icon="revealPassword2 ? 'visibility_off' : 'visibility'"
										:rules="[rules.required, rules.webdavCompliant]"
										v-on="on"
										@click:append="revealPassword2 = !revealPassword2"
									></v-text-field>
								</template>
								<span>{{ $t('signin.fields.rules') }}</span>
							</v-tooltip>
							<v-layout align-center justify-center>
								<v-checkbox v-model="cgu" color="primary" :label="$t('cgu.action')"></v-checkbox>

								<w-flex class="pointer text-underline" shrink @click="goTo('cgu-cgv')">{{ $t('cgu.read') }}</w-flex>
							</v-layout>
						</v-card-text>
						<v-card-actions>
							<v-layout align-center justify-center>
								<v-flex xs10 sm8 md6 xl6>
									<v-layout align-center justify-center my-3>
										<WButton @click="createPassword()">{{ $t('password_create.action') }}</WButton>
									</v-layout>
									<v-layout align-center justify-center>
										<p class="pointer grey--text underline mt-2" @click="goBack()">{{ $t('actions.go_back') }}</p>
									</v-layout>
								</v-flex>
							</v-layout>
						</v-card-actions>
					</v-card>
				</v-form>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import { mapState } from 'vuex'
import AppModuleGuard from '@/mixins/ModulesGuards/AppModuleGuard'

export default {
	name: 'PasswordCreate',
	mixins: [AppModuleGuard],
	data: function () {
		return {
			password: null,
			password_confirmation: null,
			cgu: false,
			revealPassword1: false,
			revealPassword2: false,
			rules: {}
		}
	},
	computed: {
		...mapState({
			logo: state => state.whitelabel.selected.logo,
			name: state => state.whitelabel.name
		})
	},
	mounted: function () {
		window.addEventListener('keydown', this.createWithEnter)
	},
	destroyed: function () {
		window.removeEventListener('keydown', this.createWithEnter)
	},
	created: function () {
		this.rules = {
			required: (value = null) => {
				let result = true
				if (!value || value.trim() === '') {
					result = this.$t('signin.errors.missing')
				}
				return result
			},
			webdavCompliant: (value = '') => {
				let result = true
				if (value) {
					const forbiddenChars = this.appService.getWebDavUnSupportedChars()

					const hasUnsupportedChar = forbiddenChars.some(char => value.indexOf(char) !== -1)

					if (hasUnsupportedChar) {
						result = this.$t('signin.errors.webdavCompliant', { chars: forbiddenChars.join(', ') })
					}
				}
				return result
			}
		}
		if (this.$route.params.token) {
			this.appService.getPasswordReset(this.$route.params.token).catch(() => {
				this.goBack()
			})
		} else {
			this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('token.error.missing'))
			//this.goBack()
		}
	},
	methods: {
		goTo: function (routePath) {
			this.appService.goTo(routePath)
		},
		createPassword: function () {
			if (!this.password) {
				this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('signin.errors.missing_password'))
			} else if (this.password.length < 8) {
				this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('signin.errors.password_length'))
			} else if (!this.password_confirmation || this.password !== this.password_confirmation) {
				this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('signin.errors.password_confirmation'))
			} else if (!this.cgu) {
				this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('cgu.errors.missing'))
			} else if (!this.$refs.form.validate()) {
				this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('security.fields.rules'))
			} else {
				this.appService.createPassword(this.password, this.password_confirmation, this.cgu, this.$route.params.token).then(() => {
					this.appEventBus.emit(this.appEvents.SNACKBAR_INFO, this.$t('password_create.message.success'))
					this.goBack()
				})
			}
		},
		goBack: function () {
			this.appService.goTo('signin')
		},
		createWithEnter: function (e) {
			if (e.key === 'Enter') {
				this.createPassword()
			}
		}
	}
}
</script>

<style scoped>
.noBackground {
	background-color: transparent !important;
}

button {
	width: 100%;
}

.underline:hover {
	text-decoration: underline;
}

img {
	margin: auto;
	width: 50px;
}
</style>
